#root {
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: auto;

    --poup-yellow: #e7ae09;
    --poup-green: #53ff1b;
}

.c-sidebar {
    background: #333333;
}

.c-header {
    background: var(--poup-green);
}

.c-subheader {
    background: rgb(255, 255, 255);
}
