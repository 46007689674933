body {
  font-family: "Helvetica", serif !important;;
}

#root {
  background: rgb(224, 164, 76);
}

.c-callout-serious {
  border-left: 4px solid $serious !important;
}

.pointer {
  cursor: pointer;
}

.fa-instagram {
  border-radius: 10%;
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 11%,
    rgba(253, 29, 29, 1) 75%,
    rgba(252, 176, 69, 1) 98%
  );
}

.icon-fix {
  position: relative;
  top: 2px;
}

.lineHeight-1 {
  line-height: 25px;
}

.BG-GRAY {
  background-color:rgba(255, 255, 255, 0.8)
}

.text-poup {
  color: #3cc010;
}

.btn-outline-poup {
    color: #3cc010;
    border-color: #3cc010;
}
